@if (isLoading()) {
  <div @fadeIn class="loader" [ngClass]="{ overlay: !inline() }">
    <mat-spinner
      [diameter]="diameter()"
      #tooltip="matTooltip"
      [matTooltip]="timeoutExplanation() | translate"
      matTooltipPosition="right"
      matTooltipShowDelay="300"
      aria-tooltip="Button that displays and hides a tooltip triggered by other buttons">
    </mat-spinner>
  </div>
}
