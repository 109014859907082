import { AfterViewInit, Component, DestroyRef, inject, input, model, OnDestroy, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTooltip } from '@angular/material/tooltip';
import { distinctUntilChanged, firstValueFrom, timer } from 'rxjs';
import { fadeIn } from '../../animations/fadeIn';
import { LoaderService } from './loader.service';

const LoaderTimeout = 20000;

@Component({
  selector: 'lib-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeIn],
  standalone: false,
})
export class LoaderComponent implements AfterViewInit, OnDestroy {
  private readonly destroyRef$ = inject(DestroyRef);
  private readonly loader = inject(LoaderService);

  isLoading = model(false);
  diameter = input(30);

  inline = input(false);
  tooltip = viewChild(MatTooltip);
  timeout = input(LoaderTimeout);
  timeoutExplanation = input('It is taking longer than expected to complete this task. Please be patient.');

  _destroyed = false;

  get size() {
    return `${this.diameter()}px`;
  }

  ngAfterViewInit(): void {
    let timeout: NodeJS.Timeout;
    this.loader
      .isLoading$()
      .pipe(takeUntilDestroyed(this.destroyRef$), distinctUntilChanged())
      .subscribe((on) => {
        if (on === true) {
          // Start loading
          this.isLoading.set(true);
          firstValueFrom(timer(this.timeout())).then(() => !this._destroyed && this.tooltip()?.show());
        } else {
          if (timeout) clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.isLoading.set(false);
            this.tooltip()?.hide();
          }, 100);
        }
      });
  }

  ngOnDestroy(): void {
    this.isLoading.set(false);
    this.tooltip()?.hide();
    this._destroyed = true;
  }
}
